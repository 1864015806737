import { DiscogsRelease, DiscogsSearchResult, DiscogsSearchResults, PlaylistResponseItem, YoutubeResponseItem } from "@/types"

// refactor to own class later
const getResultsWithMatrixInfo = function (results: DiscogsSearchResults): Array<DiscogsSearchResult> {
  const resultsWithMatrixInfo: Array<DiscogsSearchResult> = []
  if (results.results) {
    const tracks = results.results
    tracks.forEach((t: DiscogsSearchResult) => {
      if (t.format.includes("Shellac") && t.barcode.length > 1) {
        resultsWithMatrixInfo.push(t)
      }
    })
  }

  return resultsWithMatrixInfo
}

function isRelease(obj: any): obj is DiscogsRelease {
  return obj.tracklist !== undefined
}

const listTrackInfo = async function (records: Array<DiscogsSearchResult>, api: any, cb: any | null = null): Promise<Array<{ text: string; link: string; }>> {
  const tracklist: Array<{ text: string; link: string; }> = []

  let i = 0
  for (const r of records) {
    let trackA = ''
    let trackB = ''

    if (r.year) {
      trackA += r.year.toString() + ' - '
      trackB += r.year.toString() + ' - '
    } else {
      trackA += '0000 - '
      trackB += '0000 - '
    }

    await api
      .getResource(r.resource_url)
      .then((response: unknown) => {
        if (isRelease(response)) {
          if (response.tracklist.length === 2) {
            const _trackA = response.tracklist.find(t => t.position === 'A' || t.position === 'R') || response.tracklist[0]
            const _trackB = response.tracklist.find(t => t.position === 'B' || t.position === 'L') || response.tracklist[1]

            if (_trackA) {
              trackA += _trackA.title + ' - '
            } else {
              trackA += '<Title> - '
            }

            if (_trackB) {
              trackB += _trackB.title + ' - '
            } else {
              trackB += '<Title> - '
            }

            const _artist = r.title.split(' - ')[0].trim()
            trackA += _artist + ' - ('
            trackB += _artist + ' - ('

            if (response.labels && response.labels.length > 0) {
              const label = response.labels[0].name
              trackA += label + ' '
              trackB += label + ' '
            } else if (r.label) {
              trackA += r.label + ' '
              trackB += r.label + ' '
            } else {
              trackA += 'Unknown label '
              trackB += 'Unknown label '
            }

            if (response.labels && response.labels.length > 0) {
              const catno = response.labels[0].catno
              trackA += catno + ', '
              trackB += catno + ', '
            } else if (r.catno) {
              trackA += r.catno + ', '
              trackB += r.catno + ', '
            } else {
              trackA += 'unknown catalog no., '
              trackB += 'unknown catalog no., '
            }

            if (response.identifiers && response.identifiers.length >= 2) {
              const half = Math.ceil(response.identifiers.length / 2)
              const matrixA = response.identifiers.splice(0, half)
              const matrixB = response.identifiers.splice(-half)

              matrixA.forEach(m => {
                trackA += m.value + ', '
              })

              matrixB.forEach(m => {
                trackB += m.value + ', '
              })
            } else {
              trackA += 'matrix unknown, '
              trackB += 'matrix unknown, '
            }

            if (response.notes && response.notes !== '') {
              const notes = response.notes.replace(/\n/g, ' ').replace(/\s{2,}/g, ' ').trim()
              trackA += notes + ')'
              trackB += notes + ')'
            } else {
              trackA = trackA.slice(0, -2) + ')'
              trackB = trackB.slice(0, -2) + ')'
            }

            tracklist.push({ text: trackA, link: response.uri })
            tracklist.push({ text: trackB, link: response.uri })
          }
        }
      })
      .then(() => {
        i = i + 1
        if (cb) {
          return new Promise(resolve => {
            cb(i)
            setTimeout(resolve, 2000)
          })
        } else {
          return Promise.resolve()
        }
      })
  }

  return tracklist
}

const getListOfYoutubeUrls = function (items: Array<unknown>): Array<string> {
  const ids = items.map((i: unknown) => {
    if ((i as any).id.videoId) {
      return `https://www.youtube.com/watch?v=${(i as YoutubeResponseItem).id.videoId}`
    } else {
      return `https://www.youtube.com/watch?v=${(i as PlaylistResponseItem).contentDetails.videoId}`
    }
  });
  return ids;
}

const getListOfYoutubeUrlsExtraInfo = function (items: Array<unknown>): Array<string> {
  const ids = items.map((i: unknown) => {
    if ((i as any).id.videoId) {
      return `${(i as YoutubeResponseItem).snippet.title}\thttps://www.youtube.com/watch?v=${(i as YoutubeResponseItem).id.videoId}`
    } else {
      return `${(i as PlaylistResponseItem).snippet.title}\thttps://www.youtube.com/watch?v=${(i as PlaylistResponseItem).contentDetails.videoId}`
    }
  });
  return ids;
}

export {
  getResultsWithMatrixInfo,
  listTrackInfo,
  getListOfYoutubeUrls,
  getListOfYoutubeUrlsExtraInfo
}
